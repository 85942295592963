import {
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";

import * as LDClient from "launchdarkly-js-client-sdk";

import { useAuth } from "../state/auth";
import _ from "lodash";
import { User } from "../hooks/useUser";
import clsx from "clsx";
import { useEnv } from "../state/env";

const LD_KEY = "launchDarkly";

interface Props {
    children: ReactNode;
}

export interface LDFlags {
    hubspotIntegration?: boolean;
    enableEditor?: boolean;
    enableDatasetEditor?: boolean;
    enableWelcomeMessageEditor?: boolean;
    enableQuickLinkEditor?: boolean;
    enableDemoScriptEditor?: boolean;
    enableNewDemoExperienceV1?: boolean;
    enableNewTestExperienceV1?: boolean;
    enableNewDealDashboard?: boolean;
    enableCustomerSubdomains?: boolean;
    enableCustomerSubdomainsCapture?: boolean;
    enableCustomerSubdomainsSandbox?: boolean;
    enablePdFeatureGates?: boolean;
    enableSelfServeUserDeactivation?: boolean;
    enableSandboxTutorial?: boolean;
    enableNewLoginFlow?: boolean;
    enableIntuitDemoFix?: boolean;
    hideDemoPanelFooter?: boolean;
    hideSandboxPanelFooter?: boolean;
    enableDemoControlTotp?: boolean;
    enableClearUseCaseId?: boolean;
    enableDemoSandboxWhitelabeling?: boolean;
    enableNewActivationFlow?: boolean;
    enableNewResetPasswordFlow?: boolean;
    preventLeadsFetching?: boolean;
    enableNewAccountSettings?: boolean;
    enableNewAccountSettingsPersonal?: boolean;
    enableNewAccountSettingsUsers?: boolean;
    enableNewAccountSettingsIntegrations?: boolean;
    enableNewAnalyticsPage?: boolean;
    enableHootsuiteCaptureTrialRenewal?: boolean;
    enableGoogleLoginWithoutEmailVerification?: boolean;
    sendIntuitToDemoRoute?: boolean;
    enableForcedDefaultRoute?: boolean;
    openDemoControlsInNewWindow?: boolean;
    redirectExpredMagicLinkToken?: boolean; // Yes, this is an unfortunate typo
    enablePresenterMode?: boolean;
    enableWalkthroughStepSkipping?: boolean;
    preventOldAppFrameInNewSandboxExperience?: boolean;
    demoScriptFeatureShortcuts?: boolean;
}

export const EDIT_FLAGS_MANUALLY = false;

function createDefaultsFlags(defaults: Partial<LDFlags>): LDFlags {
    return {
        hubspotIntegration: false,
        enableEditor: false,
        enableDatasetEditor: false,
        enableWelcomeMessageEditor: false,
        enableQuickLinkEditor: false,
        enableDemoScriptEditor: false,
        enableNewDemoExperienceV1: false,
        enableNewTestExperienceV1: false,
        enableNewDealDashboard: false,
        enableCustomerSubdomains: false,
        enableCustomerSubdomainsCapture: false,
        enableCustomerSubdomainsSandbox: false,
        enablePdFeatureGates: false,
        enableSelfServeUserDeactivation: false,
        enableSandboxTutorial: false,
        enableNewLoginFlow: false,
        enableIntuitDemoFix: false,
        hideDemoPanelFooter: false,
        hideSandboxPanelFooter: false,
        enableDemoControlTotp: false,
        enableClearUseCaseId: false,
        enableDemoSandboxWhitelabeling: false,
        enableNewActivationFlow: false,
        enableNewResetPasswordFlow: false,
        preventLeadsFetching: false,
        enableNewAnalyticsPage: false,
        enableHootsuiteCaptureTrialRenewal: false,
        enableGoogleLoginWithoutEmailVerification: false,
        enableNewAccountSettings: false,
        enableNewAccountSettingsPersonal: false,
        enableNewAccountSettingsUsers: false,
        enableNewAccountSettingsIntegrations: false,
        sendIntuitToDemoRoute: false,
        enableForcedDefaultRoute: false,
        openDemoControlsInNewWindow: false,
        redirectExpredMagicLinkToken: false,
        enablePresenterMode: false,
        enableWalkthroughStepSkipping: false,
        preventOldAppFrameInNewSandboxExperience: false,
        demoScriptFeatureShortcuts: false,
        ...defaults,
    };
}

const LaunchDarklyContext = createContext<{
    flags: LDFlags;
    ready: boolean;
    setFlags: (flags: LDFlags) => void;
}>({ flags: {}, ready: false, setFlags: () => {} });

export function useLaunchDarkly() {
    return useContext(LaunchDarklyContext);
}

export function useFlags() {
    const { flags, ready, setFlags } = useLaunchDarkly();
    return { flags, ready, setFlags };
}

const getLocalRecord = (key): Record<string, any> => {
    const record = localStorage.getItem(key) || "";
    try {
        return JSON.parse(record);
    } catch (e) {
        return { ready: false, flags: {} };
    }
};

function getEnableEditor(flags: LDFlags) {
    return _.some([
        flags.enableDatasetEditor,
        flags.enableWelcomeMessageEditor,
        flags.enableQuickLinkEditor,
        flags.enableDemoScriptEditor,
    ]);
}

function getEnableIntuitDemoFix(originalFlags: LDFlags) {
    // We want sendIntuitToDemoRoute to supersede enableIntuitDemoFix.
    // Guarantee that when the new flag is true, the old one is false.
    if (originalFlags.sendIntuitToDemoRoute) {
        return false;
    }
    // sendIntuitToDemoRoute is false, so return whatever this was.
    return originalFlags.enableIntuitDemoFix;
}

function getLaunchDarklyUserContext(user?: User) {
    return {
        kind: "user",
        key: user?.user_id || "not-logged-in",
        email: user?.email,
        // NOTE: may need to change lines below to user?.extra_info?.{first,last}Name
        firstName: user?.first_name,
        lastName: user?.last_name,
        anonymous: !user?.user_id,
        vendor: user?.current_workspace?.deal_salesperson_company_name,
        salesperson_email: user?.current_workspace?.deal_salesperson_email,
    };
}

export function LaunchDarklyContextProvider({ children }: Props) {
    /* This context provider allows us to load our flags "on the side"
     *  and then update the flags whenever we get them
     *  It also lets us set the LAUNCH_DARKLY_FLAGS variable on the backend
     *  during local testing
     * */
    const env = useEnv();
    const { data: user, loading } = useAuth();

    const clientSideID = env?.LAUNCH_DARKLY_CLIENT_ID || "";
    const ldMockFlags = env?.LAUNCH_DARKLY_FLAGS || "";
    const [launchDarkly, setLaunchDarkly] = useState(getLocalRecord(LD_KEY));
    const client = useRef<LDClient.LDClient>();
    const isLocal = window.location.hostname.endsWith("local.testbox.com");

    /*
     * TODO (EXP-973) - make the pattern we use to get a fake `enableEditor` flag more generalized
     */
    useEffect(() => {
        const existingFlags = getLocalRecord(LD_KEY);
        const onFlags = (flags) => {
            const camelFlags = _.mapKeys(flags, (v, k) => _.camelCase(k));
            const ldItem = {
                ready: true,
                flags: {
                    ...camelFlags,
                    enableEditor: getEnableEditor(camelFlags),
                    enableIntuitDemoFix: getEnableIntuitDemoFix(camelFlags),
                },
            };
            setLaunchDarkly(ldItem);
            localStorage.setItem(LD_KEY, JSON.stringify(ldItem));
        };

        if (existingFlags.ready && isLocal) {
            onFlags(createDefaultsFlags(existingFlags.flags));
        } else if (ldMockFlags && isLocal) {
            try {
                const jsonFlags = JSON.parse(ldMockFlags);
                onFlags(jsonFlags);
            } catch (e) {
                console.error(e);
            }
        } else if (clientSideID) {
            const context = getLaunchDarklyUserContext(user);

            client.current = LDClient.initialize(clientSideID, context);
            client.current.on("ready", () => {
                const allFlags = client.current?.allFlags();
                if (allFlags) {
                    onFlags(allFlags);
                }
            });
        } else if (env) {
            // have to wait on /env to ensure we are not preemptively
            // falling back to an empty state before LDMockFlags is checked
            setLaunchDarkly({ ready: true, flags: {} });
        }
    }, [clientSideID, loading, ldMockFlags, isLocal, user, env]);

    return (
        <LaunchDarklyContext.Provider
            value={{
                flags: launchDarkly.flags || {},
                ready: launchDarkly.ready,
                setFlags: (flags: LDFlags) => {
                    setLaunchDarkly({ ready: true, flags });
                    localStorage.setItem(
                        LD_KEY,
                        JSON.stringify({ ready: true, flags })
                    );
                },
            }}
        >
            <div
                className={clsx({
                    "spec-launch-darkly-ready": launchDarkly.ready,
                })}
                style={{ display: "none" }}
            />
            {children}
        </LaunchDarklyContext.Provider>
    );
}
